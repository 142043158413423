<template>
  <div class="ModalFlex">
    <div class="Popup_Inner_main">
      <div @click="close" class="Popup_close_btn">
        <img
          @load="imgLoaded"
          class="imgHide"
          src="@/assets/media/vectors/close.svg"
          alt=""
        />
      </div>
      <div class="Popup_title">
        Versiuni produs
      </div>
      <div style="position: relative;">
        <transition-group name="popup">
          <h4
            key="0"
            v-if="!versions.length"
            class="prettyH4"
            style="color: #888;position: absolute;top: 0;left: 0;right: 0;"
          >
            <i class="fas fa-times"></i>
            Nu sunt versiuni
          </h4>
          <div
            key="1"
            v-else
            class="TBParent productsStatusesBeautify"
            style="max-height: 60rem !important;"
          >
            <table>
              <thead>
                <th style="width: 4rem;">Nr.</th>
                <th>Denumire</th>
                <th>Data creării/modificării</th>
                <th>Status versiune produs</th>
                <th>Stare produs sistem</th>
                <th style="width: 15rem;">Acțiuni</th>
              </thead>
              <tbody>
                <tr v-for="vers in versions" :key="vers._key">
                  <td>{{ vers._viewVers }}.</td>
                  <td
                    v-tooltip="vers.name"
                    style="white-space: nowrap;overflow: hidden; text-overflow: ellipsis; padding-right: 20px;"
                  >
                    {{ vers.name }}
                  </td>
                  <td>{{ vers.modifyDate }}</td>
                  <td align="center">
                    <span
                      :data="vers.productStatus.tooltip"
                      :style="vers.productStatus.css"
                      v-tooltip="vers.productStatus.tooltip"
                      >{{ vers.productStatus.tooltip }}</span
                    >
                  </td>
                  <td align="center">
                    <span
                      :data="vers.status"
                      v-tooltip="vers.statusBeautified"
                      >{{ vers.statusBeautified }}</span
                    >
                  </td>
                  <td>
                    <router-link :to="vers._path">
                      <div style="display: flex;align-items: center;">
                        <span>Deschide</span>
                        <i
                          style="margin-left: 1rem;"
                          class="fas fa-external-link-alt"
                        ></i>
                      </div>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </transition-group>
      </div>
    </div>
    <div class="Popup_footer">
      <div class="Popup_footer_col">
        <button @click="close" class="Popup_footer_btn Footer_btn1">
          Inapoi
        </button>
      </div>
      <div class="Popup_footer_col"></div>
    </div>
  </div>
</template>

<script>
import { PRODUCT_VERSIONS } from "@/api.js";
import { mapMutations } from "vuex";

export default {
  props: {
    product: {
      type: Object,
      required: false,
      default: () => null,
    },
  },
  data() {
    return {
      historyData: [],
      isVersionOpened: false,
    };
  },
  computed: {
    versions() {
      const data = [];
      const vers = this.historyData.map((e) => {
        data.push(e.data);

        return {
          name: e.data.name ?? this.product.name,
          modifyDate: this.toDateAndTime(e.createdAt) || "",
          _version: e.versionNumber ?? null,
          _viewVers: Number.isInteger(e.versionNumber)
            ? e.versionNumber + 1
            : "",
          _key: e.versionNumber ?? this.makeid(6),
          _path: this.preparePath(e.versionNumber),
        };
      });

      const combined = this.combineObjects(data);

      const getStatus = (status) => {
        return (
          {
            completed: {
              tooltip: "Validat",
              css: "background-color: #39f;",
            },
            in_progres: {
              tooltip: "În curs de validare",
              css: "background-color: #39f;",
            },
            rejected: {
              tooltip: "Respins",
              css: "background-color: red;",
            },
          }[status] || "-"
        );
      };

      combined.forEach((e, i) => {
        vers[i]._data = e;
        vers[i].status = String(e.isActive);
        vers[i].statusBeautified =
          {
            false: "Dezactivat",
            true: "Activat",
          }[e.isActive] || "-";

        vers[i].productStatus = getStatus(e.status);

        vers[i].institution = e.institution?.name || "-";
        vers[i].department = e.department?.name || "-";
        vers[i]._id = e.id ?? null;
      });

      return vers.reverse();
    },
    urlVersion() {
      return +this.$route.params.version - 1;
    },
    preparedId() {
      return this.product?.id || +this.$route.params.id || null;
    },
    validId() {
      return Number.isInteger(this.preparedId);
    },
    isUrlVersion() {
      const urlId = +this.$route.params.id;
      const x = Number.isInteger;

      return !!(x(urlId) && x(this.urlVersion));
    },
  },
  methods: {
    ...mapMutations(["addWindow", "delWindow"]),

    close() {
      this.$emit("close");
    },

    getHistoryData(forced = false) {
      const forceClose = () => {
        this.$toastr.w("Nu au fost găsite versiuni.");
        this.close();
      };
      if (!this.validId) {
        forceClose();
        return;
      }

      if (!this.historyData.length || forced) {
        const error = (msg) => {
          this.$toastr.e(
            msg || "Obținerea istoricului a eșuat. Încercați mai tâziu."
          );
          this.close();
          this.setLoad();
        };

        this.setSafeLoad(12000);
        PRODUCT_VERSIONS(this.preparedId)
          .then((res) => {
            if (Array.isArray(res?.data?.result)) {
              if (!res.data.result.length) {
                forceClose();
                return;
              }
              this.historyData = res.data.result;
              if (this.isUrlVersion) {
                this.syncUrlVersion();
              } else {
                this.setLoad();
              }
            } else {
              error();
            }
          })
          .catch(error);
      } else if (this.historyData.length) {
        this.syncUrlVersion();
      }
    },
    syncUrlVersion() {
      if (this.isUrlVersion) {
        this.setSafeLoad(12000);
        const vers = this.urlVersion;
        setTimeout(() => {
          if (this.historyData[vers]) {
            this.openVersion(vers);
          } else {
            this.$toastr.w(
              `Versiunea ${this.urlVersion + 1} a produsului nu a fost găsită.`
            );
            this.goToBasePage();
          }
          this.setLoad();
        }, 500);
      }
    },
    openVersion(vers) {
      if (!Number.isInteger(vers) || !this.historyData[vers]) return;

      const prepare = this.combineObjects(this.historyData.map((e) => e.data));
      const find = prepare[vers];

      this.isVersionOpened = true;
      this.addWindow({
        title: `Versiune V${vers + 1}.00`,
        readonly: true,
        defaultData: find,
        modal: 5.1,
        onClose: () => {
          this.isVersionOpened = false;
          this.goToBasePage();
        },
      });
    },
    preparePath(version) {
      if (!Number.isInteger(version)) return "#";

      return `/${this.$route.params.navigation}/catalog/${
        this.preparedId
      }/${version + 1}`;
    },
    syncUrl() {
      if (this.isVersionOpened && !this.isUrlVersion) {
        this.delWindow();
      } else {
        if (!this.validId) {
          this.close();
          return;
        }

        this.getHistoryData();
      }
    },
  },
  watch: {
    $route: {
      handler() {
        this.syncUrl();
      },
      immediate: true,
    },
    validId(x) {
      if (!x) {
        this.close();
      }
    },
  },
  beforeDestroy() {
    this.goToBasePage();
  },
};
</script>

<style lang="scss">
.productsStatusesBeautify span[data] {
  display: inline-block;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  box-shadow: 0px 3px 6px rgba(75, 81, 91, 0.15),
    0px 1px 3px rgba(0, 0, 0, 0.15);
  cursor: default;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  max-width: 100%;
  color: #fff;

  &[data="false"] {
    background-color: #ec4a4a;
  }
  &[data="true"] {
    background-color: #569a36;
  }
}
</style>
