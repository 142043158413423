<template>
  <div style="padding-left: 1.75rem">
    <clasic-modal v-model="modalOpen" extendedMode="1">
      <history :product="modalData" @close="modalOpen = false" />
    </clasic-modal>
    <div class="col2_head">
      <router-link :to="'/'" class="breadcrumb_home">Acasă /</router-link>
      {{ $route.name }}
      <div class="subHeadTitle">{{ $route.name }} produse/lucrări/servicii</div>
      <div class="right" v-if="PERMISIONS.create">
        <div class="right" style="margin-top: 1.2rem">
          <div @click="addItem" class="Button1 Btn_center">
            <div class="icon">
              <img
                @load="imgLoaded"
                src="../assets/media/for_table/circle_ADD_new_etap.svg"
                alt=""
              />
            </div>
            <span class="Btn_content">
              Adaugă
            </span>
          </div>
        </div>
      </div>
    </div>
    <template>
      <template v-if="PERMISIONS.list">
        <table-parent
          :reloadCount="reloadTable"
          :tableHead="tableHead"
          :prepareFn="prepareData"
          :apiModule="tableModule"
          apiModuleName="PRODUCTS_PAGINATED"
          :tableMinimizeHead="true"
          :tableSpread="true"
          @Btn2="editItem"
          @Btn3="deleteItem"
          @Btn8="(i) => editStatus(i, true)"
          @Btn9="(i) => editStatus(i, false)"
          @Btn13="viewHistory"
          @Btn30="completeProduct"
          @totalRecords="(x) => (totalRecords = x)"
        />
      </template>
      <template v-else>
        <h3 style="display: flex; flex-direction: column; align-items: center">
          <span style="font-size: 4rem">
            <i class="fas fa-exclamation-triangle"></i>
          </span>
          Acces interzis
        </h3>
      </template>
    </template>
  </div>
</template>

<script>
import PermisionsMixin from "@/mixins/productsPermMixin.js";
import updateRoleStatus from "../mixins/updateUserRoleStatus.js";
import {
  PRODUCTS_PAGINATED,
  PRODUCTS_MODIFY,
  PRODUCTS_CAN_EDIT_CPV,
  PRODUCTS_ONCE,
  PRODUCT_LAST_VERSION,
} from "../api.js";
import { mapMutations } from "vuex";
import TableParent from "@/components/TableParent";
import History from "@/components/CatalogHistory";
import DialogModal from "@/components/DialogModal";
import CatalogAchizitionsSelect from "@/components/CatalogAchizitionsSelect";
import CPVSelect from "@/components/CPVSelect";
import CatalogSelect from "@/components/CatalogSelect";

export default {
  name: "Catalog",
  $Title() {
    return `ConectX - ${this.$route.name}`;
  },
  mixins: [PermisionsMixin, updateRoleStatus],
  inject: ["products"],
  components: {
    TableParent,
    History,
  },
  data() {
    return {
      tableHead: [
        {
          title: "Denumire produs",
          sort: true,
          queryKey: "name",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Denumire grup",
          sort: true,
          queryKey: "cpvCategory.group",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Denumire Clasă",
          sort: true,
          queryKey: "cpvCategory.class",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Denumire Categorie",
          sort: true,
          queryKey: "cpvCategory.category",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Cod CPV",
          sort: true,
          queryKey: "cpvCategory.cpvCode",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Descriere produs",
          sort: true,
          queryKey: "description",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Unitatea de măsură",
          sort: true,
          queryKey: "measurementUnit.shortName",
          bottomHead: {
            quikFiltre: true,
          },
        },
        /*      {
          title: "Instituția",
          sort: true,
          queryKey: "institution.name",
          bottomHead: {
            quikFiltre: true,
          },
        }, */
        {
          title: "Data creării",
          queryKey: "createdAt",
          bottomHead: {
            quikFiltre: "date",
            range: true,
          },
        },
        {
          spacer: true,
          minimize: 1,
          fixed: {
            right: true,
          },
          queryKey: "status",
          bottomHead: {
            iconsMap: [
              {
                tooltip: "Completat",
                icon: "fas fa-clipboard-check",
                color: "#39f",
              },
              {
                tooltip: "În progres",
                icon: "fad fa-hourglass",
                color: "#39f",
              },
              { icon: "fas fa-times-circle", tooltip: "Respins", color: "red" },
            ],
            valuesMap: { value: [false, false, false] },
            queryMap: ["completed", "in_progres", "rejected"],
          },
        },
        {
          spacer: true,
          minimize: 1,
          fixed: {
            right: true,
          },
          queryKey: "isActive",
          bottomHead: {
            iconsMap: [
              {
                tooltip: "Activat",
                icon: "fas fa-check-circle",
                color: "green",
              },
              {
                tooltip: "Dezactivat",
                icon: "fas fa-exclamation-circle",
                color: "red",
              },
            ],
            valuesMap: { value: [false, false] },
            queryMap: ["1", "0"],
          },
        },
      ],
      tableModule: PRODUCTS_PAGINATED,
      totalRecords: 0,
      reloadTable: 0,
      modalOpen: false,
      modalData: null,
    };
  },
  computed: {
    disabledCols() {
      const disabledColsMap = {
        1: [],
        2: [8],
        3: [8],
      };
      return disabledColsMap[this.currentRoleStatus] || [];
    },
  },
  watch: {
    $route: {
      handler(x) {
        if (Number.isInteger(+x.params.version)) {
          this.modalOpen = true;
        }
        this.notificationAction();
      },
      immediate: true,
    },
    products: {
      handler() {
        this.reload();
      },
      deep: true,
    },
    modalOpen(x) {
      if (!x) {
        this.modalData = null;
      }
    },
  },
  methods: {
    ...mapMutations({ addWindow: "addWindow" }),
    reload() {
      this.reloadTable++;
    },
    itemDescription(x) {
      return x
        ? `<b>Denumire</b>: ${x.cpvCode} - ${x.name}\n<b>Diviziunea</b>: ${x.division}\n<b>Grupul</b>: ${x.group}\n<b>Clasa</b>: ${x.class}\n<b>Categoria</b>: ${x.category}`
        : "";
    },
    prepareData(row) {
      let rowCpvCode = "-";

      if (row.cpvCategory?.cpvCode) {
        if (row.cpvCategory.name) {
          rowCpvCode = {
            val: row.cpvCategory.cpvCode,
            tooltip: row.cpvCategory.name,
          };
        } else {
          rowCpvCode = row.cpvCategory.cpvCode;
        }
      }
      let category = "";
      let group = "";
      let className = "";
      if (row.cpvCategory) {
        group = row.cpvCategory.group || "-";
        className = row.cpvCategory.class || "-";
        category = row.cpvCategory.category || "-";
      }

      const preparation = [
        row.name || "-",
        group,
        className,
        category,
        rowCpvCode,
        row.description,
        row.measurementUnit
          ? {
              val: row.measurementUnit.shortName,
              tooltip: row.measurementUnit.description,
            }
          : "-",
        //row.institution?.name || "-",
        this.toDate(row.createdAt),
        {
          completed: {
            border: "left",
            tooltip: "Validat",
            css: "font-size:2.2rem;color:#39f;",
            icon: "fas fa-clipboard-check",
          },
          in_progres: {
            border: "left",
            tooltip: "În curs de validare",
            css: "font-size:2.2rem;color:#39f;",
            icon: "fad fa-hourglass",
          },
          rejected: {
            border: "left",
            tooltip: "Respins",
            css: "font-size:2.2rem;color:red;",
            icon: "fas fa-times-circle",
          },
        }[row.status] || "-",
        {
          true: {
            border: "left",
            tooltip: "Activ",
            css: "font-size:2.2rem;color:green;",
            icon: "fas fa-check-circle",
          },
          false: {
            border: "left",
            tooltip: "Dezactivat",
            css: "font-size:2.2rem;color:red;",
            icon: "fas fa-exclamation-circle",
          },
        }[row.isActive] || "",
      ];

      const uuid = row.createdByUser;
      const x = this.PERMISIONS;
      const prepareBtn = [];

      const checkOwner = (o) => {
        if (!o) return false;
        if (o === true) return true;
        if (o == uuid) {
          return true;
        }
        if (
          Array.isArray(o) &&
          o.length > 1 &&
          o[0].includes(status) &&
          o[1] == uuid
        ) {
          return true;
        }
        return false;
      };

      if (
        ["superadmin", "sef_institutie"].includes(this.getUserRole()) &&
        checkOwner(x.edit)
      ) {
        prepareBtn.push(2);
      }
      if (checkOwner(x.enable) && row.isActive === false) {
        prepareBtn.push([8, "Activează"]);
      }
      if (checkOwner(x.disable) && row.isActive === true) {
        prepareBtn.push([9, "Dezactivează"]);
      }
      if (["in_progres"].includes(row.status) && checkOwner(x.confirm)) {
        prepareBtn.push([
          30,
          row.status == "in_progres"
            ? "Aprobă"
            : "Modifică categoriile de achiziție <br/> și clasificațiile bugetare",
          ["fas fa-edit", "font-size:2.2rem;color:#39f;"],
        ]);
      }

      if (checkOwner(x.delete) && row.status == "in_progres") {
        prepareBtn.push(3);
      }

      prepareBtn.push(13);

      if (prepareBtn.length) {
        preparation.push([prepareBtn]);
      }

      return preparation;
    },
    addItem() {
      this.addWindow({
        title: "Adaugă Poziție de catalog",
        modal: 5,
      });
    },
    editItem(item) {
      if (!Number.isInteger(item.id)) {
        this.$toastr.e("Anunțați administratorul.");
        return;
      }
      this.addWindow({
        title: "Modifică poziție de catalog",
        productId: item.id,
        modal: 5.1,
      });
    },
    deleteItem(item) {
      if (!Number.isInteger(item.id)) {
        this.$toastr.e("Anunțați administratorul.");
        return;
      }

      this.addWindow({
        title: "Șterge poziție de catalog",
        productId: item.id,
        productData: {},
        modal: 5.2,
      });
    },
    editStatus(item, isActive) {
      if (!Number.isInteger(item?.id)) {
        this.$toastr.e("Error code #641287");
        return;
      }

      const error = (msg) => {
        this.$toastr.e(msg || "Încercați mai târziu");

        this.reload();
        this.setLoad();
      };
      const modifyStatusFn = () =>
        PRODUCTS_MODIFY(item.id, { isActive })
          .then((res) => {
            if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
              this.$toastr.s(
                `Poziția de catalog a fost ${
                  isActive ? "activată" : "dezactivată"
                }.`
              );

              this.setLoad();
              this.reload();
            } else {
              error();
            }
          })
          .catch(error);

      this.setSafeLoad();
      if (isActive) {
        modifyStatusFn();
      } else {
        PRODUCTS_CAN_EDIT_CPV(item.id)
          .then((res) => {
            if (
              typeof res?.data?.result == "number" &&
              res.data.result > 0 &&
              res.data.result < 4
            ) {
              const status = res.data.result;

              if (status === 1) {
                this.$toastr.w({
                  msg:
                    "Poziția de catalog nu poate fi dezactivată deoarece pe baza acesteia au fost create propuneri de nevoi care deja fac parte dintr-un referat de necesitate.",
                  timeout: 12000,
                });
                this.setLoad();
                return;
              }
              if (status === 2) {
                this.$modal.show(
                  DialogModal,
                  {
                    target: "ProductDeleteConfirm",
                    title: "Dezactivare poziție de catalog",
                    content: `<span style="color:#f44336;display: block;margin-top: 2rem;"><i class="fas fa-exclamation-circle" style="font-size: 6rem;"></i> <p style="font-weight: 500;font-size: 2.2rem;">După dezactivarea acestui produs, unele propuneri de nevoi create pe baza acestuia vor fi anulate automat.</p></span>`,
                    closeBtnText: "Anulează",
                    button: {
                      type: 3,
                      value: "Confirmă",
                      handler: () => {
                        modifyStatusFn();
                      },
                    },
                  },
                  {
                    name: "ProductDeleteConfirm",
                    adaptive: true,
                    width: "650",
                    height: "400",
                  },
                  {
                    "before-close": function() {},
                  }
                );
                return;
              }

              modifyStatusFn();
            } else
              error(
                `Nu s-a putut verifica dacă produsul poate fi ${
                  isActive ? "activat" : "dezactivat"
                }.`
              );
          })
          .catch(error);
      }
    },
    viewHistory(item) {
      if (!Number.isInteger(item?.id)) {
        this.$toastr.e("Error code #091247");
        return;
      }

      this.modalData = item;
      this.modalOpen = true;
    },
    async completeProduct(item) {
      if (!Number.isInteger(item?.id)) {
        this.$toastr.e("Error code #091248");
        return;
      }
      const isCompleting = item.status == "in_progres";

      const startEdit = () => {
        this.$modal.show(
          DialogModal,
          {
            target: "ProductComplete",
            title: `Aprobă poziția de catalog`,
            closeBtnText: "Anulează",
            inputs: [
              {
                id: "completedProduct",
                type: "component",
                errReason: "Selectați codul CPV",
                component: CPVSelect,
                componentProps: {
                  initial: item,
                },
              },
            ],
            acceptFinish(data) {
              const x = data?.completedProduct;

              if (
                !isCompleting &&
                this.compareUnsortedArraysOfObj(
                  [item.cpvCategory.id],
                  [x.cpvCategory.id],
                  []
                )
              ) {
                this.$toastr.w("Nu au fost găsite modificări.");
                return false;
              }

              return true;
            },
            /*     secondaryButton: {
              type: 3,
              value: "Respinge",
              handler: (data) => {

                const x = data?.completedProduct;

                if (!x) return;

                const error = (msg) => {
                  this.$toastr.e(
                    `Respingerea produsului a eșuat.${msg ? ` (${msg})` : ""}`
                  );

                  this.reload();
                  this.setLoad();
                };
                this.setSafeLoad();
                PRODUCTS_MODIFY(
                  item.id,
                  Object.assign({}, isCompleting ? { status: "rejected" } : {})
                )
                  .then((res) => {
                    if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                      this.$toastr.s(`Produsul a fost respins.`);

                      this.reload();
                      this.setLoad();
                    } else {
                      error();
                    }
                  })
                  .catch(error);
              },
            }, */
            button: {
              type: 2,
              value: isCompleting ? "Aprobă" : "Modifică",
              handler: (data) => {
                const x = data?.completedProduct;
                if (!x) return;

                const error = (msg) => {
                  this.$toastr.e(
                    `${
                      isCompleting ? "Completarea" : "Modificarea"
                    } produsului a eșuat.${msg ? ` (${msg})` : ""}`
                  );

                  this.reload();
                  this.setLoad();
                };
                this.setSafeLoad();
                PRODUCTS_MODIFY(
                  item.id,
                  Object.assign(
                    {
                      cpvCategory: x.cpvCategory.id,
                      description: this.itemDescription(x.cpvCategory),
                      name: x.name,
                      measurementUnit: x.measurementUnit.id,
                    },
                    isCompleting ? { status: "completed" } : {}
                  )
                )
                  .then((res) => {
                    if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                      this.$toastr.s(`Poziția de catalog a fost aprobată`);
                      this.reload();
                      this.setLoad();
                    } else {
                      error();
                    }
                  })
                  .catch(error);
              },
            },
          },
          {
            name: "ProductComplete",
            adaptive: true,
            width: "850",
            height: "900",
          },
          {
            "before-close": function() {},
          }
        );
      };

      const canEdit = isCompleting
        ? 3
        : await new Promise(async (r) => {
            this.setSafeLoad();
            r(
              await PRODUCTS_CAN_EDIT_CPV(item.id).then(
                (res) => res?.data?.result
              )
            );
            this.setLoad();
          });

      if (canEdit !== 3) {
        if (canEdit === 1) {
          this.$toastr.w({
            msg:
              "Poziția de catalog nu poate fi modificată deoarece pe baza acesteia au fost create propuneri de nevoi care deja fac parte dintr-un referat de necesitate.",
            timeout: 12000,
          });
          return;
        }
        if (canEdit === 2) {
          this.$modal.show(
            DialogModal,
            {
              target: "ProductEditConfirm",
              title: "Editare poziție de catalog",
              content:
                '<span style="color:#f44336;display: block;margin-top: 2rem;"><i class="fas fa-exclamation-circle" style="font-size: 6rem;"></i> <p style="font-weight: 500;font-size: 2.2rem;">După editarea acestui produs, unele propuneri de nevoi create pe baza acestuia vor fi anulate automat.</p></span>',
              closeBtnText: "Anulează",
              button: {
                type: 2,
                value: "Confirmă",
                handler: startEdit,
              },
            },
            {
              name: "ProductEditConfirm",
              adaptive: true,
              width: "650",
              height: "400",
            },
            {
              "before-close": function() {},
            }
          );
          return;
        }

        this.$toastr.e("Verificarea produsului a eșuat.");
        return;
      }

      startEdit();
    },

    notificationAction() {
      let id = this.$route.query.product;

      if (!id) return;

      this.$router.replace({ path: `/planificare/catalog` });

      this.setSafeLoad(true);
      PRODUCTS_ONCE(id)
        .then((res) => {
          if (!this.isObject(res?.data?.result)) {
            this.$toastr.e("Încercați mai târziu");
            this.setLoad();
            return;
          }

          if (res?.data?.result?.status == "in_progres") {
            this.completeProduct(res?.data?.result);
            this.setLoad();
          } else {
            PRODUCT_LAST_VERSION(id).then((res) => {
              this.setLoad();
              if (
                !this.isObject(res?.data?.result) ||
                !res?.data?.result?.versionNumber
              ) {
                this.$toastr.e("Încercați mai târziu");
                this.setLoad();
                return;
              }

              const version = res?.data?.result?.versionNumber;

              this.$router.push(
                `/${this.$route.params.navigation}/catalog/${id}/${version}`
              );
              this.setLoad();
            });
          }
        })
        .catch((err) => {
          this.$toastr.e(err);
          this.setLoad();
        });
    },
  },

  created() {
    if (!this.PERMISIONS.list) {
      return;
    }

    this.notificationAction();

    const canViewButtonsColumn = ["list", "edit", "delete"];

    this.updateRoleStatus();
    if (this.checkParamsInObj(this.PERMISIONS, canViewButtonsColumn)) {
      this.tableHead.push({
        spacer: true,
        minimize: 1,
        fixed: {
          right: true,
        },
        bottomHead: {
          quikFiltre: "clear",
        },
      });
    }
  },
};
</script>
